
.ck-content{
    max-width: 980px;
    margin: 0 auto;
    h2{
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 10px 0;
    }
    h3{
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin: 10px 0;
    }
    h4{
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin: 10px 0;
    }
    p{
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 10px 0;
    }
}