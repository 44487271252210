@import "../../../uiColors";

.rounded-3 .r-select{
  &__control {
    border:none;
    background: transparent;
    min-height: 28px;
    cursor: text;
    &:hover {
    }
    &--is-focused {
      &:hover {
      }
    }
  }
  &__multi-value{
    background-color: $gray-100;
    &__label{
      padding: 1px;
    }
    &__remove{
      &:hover{
        color: $red-500;
        background-color: $gray-200;
      }
    }
  }
  &__single-value{
    color:white;
    margin: 0;
  }
  &__indicators{
    display: none;
  }
  &__control--is-focused{
    box-shadow:none;
  }
  &__indicator-separator{
    display: none;
  }
  &__value-container{
    //height: 28px;
    padding: 0 4px;
  }
  &__indicator{
    padding: 0 8px;
  }
  &__input-container {
  }
  &__value-container {
  }
  &__single-value {
    &--is-disabled {
    }
  }
  &__indicator-separator{
  }
  &__menu{
    margin: 0;
    border: none;
    box-shadow: none;
    padding: 0;
    z-index: 30;
  }
  &__menu-list{
    border: 1px solid $gray-300;
    box-shadow: none;
    border-radius: 0;
    padding: 4px 0;
  }
  &__option,.custom-option{
    padding: 4px;
    line-height:20px;
    color:$dark-black;
    cursor: pointer;

    &:active {
      background: $gray-250;
    }
    &--is-selected{
      background: $gray-250;
    }
    &--is-focused{
      background: $gray-200;
    }
    &--is-active{
      background: $gray-250;
    }
  }
  .custom-option{
    svg{
    }
  }
}