@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;800&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont,  'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#282c34;
  color:white;
  font-weight:300;
  min-height: 100vh;
  padding-bottom: 65px;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}